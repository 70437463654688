import { ScheduleProgram } from "../../../types/ScheduleProgram";
import { Channel } from "../../../types/Channel";
import { Affiliate } from "../../../types/Affiliate";
import useTundraStore from "../../../store/useTundraStore";
import { splitProgramsByDay } from "../../../utils/splitProgramsByDay";
import splitProgramsByChannel from "../../../utils/splitProgramsByChannel";
import markConflictingEvents from "../../../utils/markConflictingEvents";
import {
  BlockHeader,
  ItemHeader,
  Items,
  ScaleContainer,
  EmissionsContainer,
} from "../../../components/EmissionsCalendar/EmissionsCalendar";
import { Box, Typography } from "@mui/material";
import { getChannelKey } from "../../../utils/getChannelKey";
import getChannelLabel from "../../../utils/getChannelLabel";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Day from "../../../components/Day/Day";
import formatWeekNumber from "../../../utils/formatWeekNumber";
import formatDay from "../../../utils/formatDay";
import { useMemo } from "react";
import { VariableSizeGrid as Grid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Scale from "../../../components/Scale/Scale";
import { useRef } from "react";

export const EmissionsByChannel = ({
  programs,
  channels,
}: {
  programs: ScheduleProgram[];
  channels: { channels: Channel[]; affiliates: Affiliate[] };
}) => {
  const pickerStartDate = useTundraStore((state) => state.pickerStartDate);
  const pickerEndDate = useTundraStore((state) => state.pickerEndDate);
  const uiChannels = useTundraStore((state) => state.uiChannels);
  const startOfDay = useTundraStore((state) => state.startOfDay);
  const baseWidth = useTundraStore((state) => state.baseWidth);
  const programsByChannel = useMemo(() => {
    const result = splitProgramsByChannel(programs, uiChannels);
    return result;
  }, [programs, uiChannels]);

  const programsByChannelByDate = useMemo(() => {
    const result = programsByChannel
      .map(({ channel, programs }) => ({
        channel,
        programsByDay: splitProgramsByDay(
          programs,
          pickerStartDate,
          pickerEndDate,
          startOfDay
        )
          .map(({ date, programs }) => ({
            date,
            programs: markConflictingEvents(programs),
          }))
          .filter(({ programs }) => programs.length > 0),
      }))
      .filter(({ programsByDay }) => programsByDay.length > 0);
    return result;
  }, [programsByChannel, pickerStartDate, pickerEndDate, startOfDay]);
  const basicHeight = useTundraStore((state) => state.zoomLevel) / 100;
  const scaleRef = useRef<HTMLDivElement>(null);
  let channelCounts: number[] = programsByChannelByDate.map(
    (p) => p.programsByDay.length
  );

  return (
    <>
      <ScaleContainer>
        <Scale startOfDay={startOfDay} ref={scaleRef} />
      </ScaleContainer>
      <EmissionsContainer>
        <AutoSizer>
          {({ width, height }: any) => (
            <Grid
              columnCount={programsByChannelByDate.length}
              columnWidth={(index) => channelCounts[index] * baseWidth + 30}
              height={height}
              rowCount={1}
              rowHeight={() => basicHeight}
              width={width}
            >
              {({ columnIndex, style }) => {
                const { channel, programsByDay } =
                  programsByChannelByDate[columnIndex];

                return (
                  <Box
                    marginRight="24px"
                    key={getChannelKey(channel)}
                    style={style}
                  >
                    <BlockHeader>
                      <Typography fontSize={18} fontWeight="bold">
                        {getChannelLabel(channel, channels)}
                      </Typography>
                    </BlockHeader>
                    <Items>
                      {programsByDay.map(({ date, programs }) => (
                        <Box
                          position="relative"
                          width={`${baseWidth}px`}
                          key={date}
                        >
                          <ItemHeader style={{ 
                            position: "sticky", 
                            top: 0, 
                            zIndex: 10, 
                            background: "white"
                          }}>
                            <Tooltip title={`${formatWeekNumber(date)} week`}>
                              <Typography
                                fontWeight="bold"
                                color="text.primary"
                              >
                                {formatDay(date)}
                              </Typography>
                            </Tooltip>
                          </ItemHeader>
                          <Day
                            programs={programs}
                            startOfDay={startOfDay}
                            date={date}
                          />
                        </Box>
                      ))}
                    </Items>
                  </Box>
                );
              }}
            </Grid>
          )}
        </AutoSizer>
      </EmissionsContainer>
    </>
  );
};
