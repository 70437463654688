import { GridProgram } from "../types/GridProgram";
import { GRID_BACKGROUND_WHITE, GRID_FONT_BLACK } from "../colors";

type GridItemStyle = {
  backgroundColor: string;
  color: string;
  isBold: boolean;
};

type ColorRule = {
  name: string;
  background: string;
  color: string;
  isBold: boolean;
  broadcastSpecific?: {
    broadcastTypeId: string;
    color: string;
  }[];
  description: string;
};

// extracted with care from database of ancient tundra.eurosport-tv.com
export const GRID_COLOR_RULES: ColorRule[] = [
  {
    name: "NEWS",
    background: "#FF0000",
    color: "#000000",
    isBold: true,
    broadcastSpecific: [],
    description: "Title has 'news' in it",
  },
  {
    name: "THE_MINUTE",
    background: "#FFFFFF",
    color: "#000000",
    isBold: true,
    broadcastSpecific: [
      {
        broadcastTypeId: "6",
        color: "#0000FF",
      },
    ],
    description: "Title has 'the minute' in it",
  },
  {
    name: "FDECRORE2",
    background: "#FFCECE",
    color: "#000000",
    isBold: true,
    broadcastSpecific: [
      {
        broadcastTypeId: "1",
        color: "#FF0000",
      },
      {
        broadcastTypeId: "2",
        color: "#008000",
      },
      {
        broadcastTypeId: "3",
        color: "#FF0000",
      },
      {
        broadcastTypeId: "4",
        color: "#0000FF",
      },
      {
        broadcastTypeId: "5",
        color: "#000000",
      },
      {
        broadcastTypeId: "6",
        color: "#008000",
      },
    ],
    description: "isFakeWindows && isReplayE2",
  },
  {
    name: "FDECRO",
    background: "#FFCECE",
    color: "#000000",
    isBold: false,
    broadcastSpecific: [
      {
        broadcastTypeId: "1",
        color: "#FF0000",
      },
      {
        broadcastTypeId: "2",
        color: "#008000",
      },
      {
        broadcastTypeId: "3",
        color: "#FF0000",
      },
      {
        broadcastTypeId: "4",
        color: "#0000FF",
      },
      {
        broadcastTypeId: "5",
        color: "#000000",
      },
      {
        broadcastTypeId: "6",
        color: "#008000",
      },
    ],
    description: "isFakeWindows but not isReplayE2",
  },
  {
    name: "DECRORE2",
    background: "#9CE8A7",
    color: "#000000",
    isBold: true,
    broadcastSpecific: [
      {
        broadcastTypeId: "1",
        color: "#FF0000",
      },
      {
        broadcastTypeId: "2",
        color: "#008000",
      },
      {
        broadcastTypeId: "4",
        color: "#0000FF",
      },
      {
        broadcastTypeId: "5",
        color: "#000000",
      },
      {
        broadcastTypeId: "6",
        color: "#008000",
      },
    ],
    description: "isDecro and isReplayE2",
  },
  {
    name: "DECRO",
    background: "#9CE8A7",
    color: "#000000",
    isBold: false,
    broadcastSpecific: [
      {
        broadcastTypeId: "1",
        color: "#FF0000",
      },
      {
        broadcastTypeId: "2",
        color: "#008000",
      },
      {
        broadcastTypeId: "4",
        color: "#0000FF",
      },
      {
        broadcastTypeId: "5",
        color: "#000000",
      },
      {
        broadcastTypeId: "6",
        color: "#008000",
      },
    ],
    description: "isDecro but not isReplayE2",
  },
  {
    name: "REPLAY_E2",
    background: "#CCCCCC",
    color: "#000000",
    isBold: true,
    description: "isReplayE2 and either doesnt have mother channel or isDecro)",
  },
  {
    name: "1",
    background: "#FFFFFF",
    color: "#FF0000",
    isBold: true,
    description: "is Live",
  },
  {
    name: "3",
    background: "#FFFFFF",
    color: "#FF0000",
    isBold: true,
    description: "L/RX",
  },
  {
    name: "6",
    background: "#00BC55",
    color: "#FFFFFF",
    isBold: true,
    description: "VTR",
  },
  {
    name: "2",
    background: "#FFFFFF",
    color: "#008000",
    isBold: true,
    description: "HLTS",
  },
  {
    name: "4",
    background: "#FFFFFF",
    color: "#0000FF",
    isBold: true,
    description: "RX",
  },
  {
    name: "5",
    background: "#CCCCCC",
    color: "#000000",
    isBold: false,
    description: "Replay",
  },
  {
    name: "8",
    background: "#CCCCCC",
    color: "#000000",
    isBold: false,
    description: "Replay*",
  },
];

const getStyleByIdAndBroadcastId = (
  id: string,
  broadcastId: number
): GridItemStyle => {
  const style: ColorRule = GRID_COLOR_RULES.find((s) => s.name === id) || {
    name: "default",
    background: GRID_BACKGROUND_WHITE,
    color: GRID_FONT_BLACK,
    isBold: false,
    description: "default",
  };

  if (style.broadcastSpecific !== undefined) {
    const broadcastSpecific = style.broadcastSpecific?.find(
      (s) => s.broadcastTypeId === broadcastId.toString()
    );
    if (broadcastSpecific) {
      return {
        backgroundColor: style.background,
        color: broadcastSpecific.color,
        isBold: style.isBold,
      };
    }
  }

  return {
    backgroundColor: style.background,
    color: style.color,
    isBold: style.isBold,
  };
};

export const getGridColors = ({
  broadcastId,
  isDecro,
  isFakeWindows,
  isReplayE2,
  hasMotherChannel,
  title,
}: GridProgram): GridItemStyle => {
  if (title.toLowerCase().includes("the minute")) {
    return getStyleByIdAndBroadcastId("THE_MINUTE", broadcastId);
  }
  if (title.toLowerCase().includes("news")) {
    return getStyleByIdAndBroadcastId("NEWS", broadcastId);
  }
  if (isFakeWindows && isReplayE2) {
    return getStyleByIdAndBroadcastId("FDECRORE2", broadcastId);
  }
  if (isFakeWindows) {
    return getStyleByIdAndBroadcastId("FDECRO", broadcastId);
  }
  if (isDecro && isReplayE2) {
    return getStyleByIdAndBroadcastId("DECRORE2", broadcastId);
  }
  if (isDecro) {
    return getStyleByIdAndBroadcastId("DECRO", broadcastId);
  }
  if (isReplayE2 && (!hasMotherChannel || isDecro)) {
    return getStyleByIdAndBroadcastId("REPLAY_E2", broadcastId);
  }
  return getStyleByIdAndBroadcastId(broadcastId.toString(), broadcastId);
};
